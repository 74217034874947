$iphone5-width: 350px;
$iphone6-width: 375px;
$tablet-width: 768px;
$tablet-p-width: 991px;
$desktop-width: 1024px;
$desktop-width-lg: 1500px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin scale($value: 1.2) {
  transition: all 250ms ease-in-out;

  &:hover {
    transform: scale($value);
  }

  @include mobile {
    &:hover {
      transform: none;
    }
  }
}

@mixin textUnderline {
  display: inline-block;
  position: relative;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.25rem;
    bottom: 0;
    left: 0;
    border-radius: 0.25rem;
    transform: scaleX(0);
    background: $primary;
    transform-origin: bottom right;
    transition: transform 250ms ease-in-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
