@import "./colors";
@import "./mixins";
@import "./typography";

.section-heading {
  @include section-heading;
  margin-bottom: 2.5rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: calc(100% + 0.5rem);
    height: 5px;
    width: 3rem;
    background: $primary;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
}

.section-container {
  max-width: 120rem;
  margin: auto;
  width: 92%;
}
