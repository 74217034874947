@import "./colors";
@import "./mixins";

@mixin logo-text {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;

  @include mobile {
    font-size: 0.8rem;
  }
}

@mixin nav-link {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

@mixin page-title {
  font-size: 2.5rem;

  @include tablet {
    text-align: center;
  }

  @include mobile {
    font-size: 2.125rem;
    text-align: center;
  }
}

@mixin page-description {
  font-size: 1.5rem;
  line-height: 1.6;

  @include tablet {
    text-align: center;
  }

  @include mobile {
    font-size: 1.2rem;
    text-align: center;
  }
}

@mixin section-heading {
  font-size: 2.8rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;

  @include mobile {
    font-size: 1.5rem;
  }
}

@mixin section-description {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;

  @include mobile {
    font-size: 1rem;
  }
}

@mixin section-subheading {
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;

  @include mobile {
    font-size: 1.25rem;
  }
}

@mixin section-detail {
  font-size: 1.2rem;
  line-height: 1.7;

  @include mobile {
    font-size: 1rem;
  }
}

@mixin tech-chip {
  font-size: 1rem;
  letter-spacing: 0;
  font-weight: 600;

  @include mobile {
    font-size: 0.8rem;
  }
}

@mixin form-label {
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 700;
  text-align: left;
}

@mixin form-input {
  font-size: 1rem;
  letter-spacing: 0;
  font-weight: 600;
}

@mixin footer-heading {
  font-size: 1.25rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin footer-description {
  font-size: 0.8rem;
  line-height: 1.7;
}

@mixin copyright-text {
  font-size: 0.8rem;
  line-height: 1.7;
}

@mixin button-text {
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;

  @include mobile {
    font-size: 0.8rem;
  }

  @include tablet {
    font-size: 0.9rem;
  }
}
