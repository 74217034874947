@import url("https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "./index.scss";

* {
  font-family: "Spectral", serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

#root {
  --section-top-padding: 6rem;

  @include tablet {
    --section-top-padding: 4rem;
  }

  @include mobile {
    --section-top-padding: 4rem;
  }
}

section {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}

// ========== CSS for custom cursor ==========
body {
  cursor: none;

  @include tablet {
    cursor: auto;
  }

  @include mobile {
    cursor: auto;
  }
}

.cursor-dot {
  width: 5px;
  height: 5px;
  background-color: $primary;
}

.cursor-outline {
  width: 30px;
  height: 30px;
  border: 2px solid rgba($primary, 0.5);
}

.cursor-dot,
.cursor-outline {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 100000;
  pointer-events: none;

  @include tablet {
    display: none;
  }

  @include mobile {
    display: none;
  }
}
// ========== CSS for custom cursor ==========
